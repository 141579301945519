/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/nova/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

:root {
	--color-primary: #f2f2f7;
	--color-danger: #fa0317;
	--color-success: #41f1b6;
	--color-warning: #ffbb55;
	--color-white: #ffffff;
	--color-info-dark: #7d8da1;
	--color-dark: #363949;
	--color-light-gray: #8d8d8d;
	--color-mid-gray: #6c6c6c;
	--color-dark-gray: #3a3a3a;
	--color-light: rgb(132, 139, 200, 0.18);
	--color-primary-variant: #111e88;
	--color-dark-variant: #677483;
	--color-background: #f6f6f9;
	--color-ease: #659de8;
	--color-license-unused: #aeaeb2;
	--color-license-used: #c686ff;
	--color-emoji-sent: #63e4b8;
	--color-users-joined: #ffd37e;
	--color-contacts-joined: #db2ed7;
	--color-gray: #808080ff;
	--color-placeholder-text: rgba(0, 0, 0, 0.48);
	--color-home-text: rgba(0, 0, 0, 0.66);
	--color-aside-nav-background: #2b2b2b;
	--color-text: rgba(0, 0, 0, 0.87);
	--color-header: var(--color-white);
	--color-btn-help-text-dark: var(--color-info-dark);

	--color-label-background: #49454f;

	--card-border-radius: 2rem;
	--card-background-color: var(--color-white);
	--card-border-color: var(--color-white);

	--border-radius-1: 0.4rem;
	--border-radius-2: 0.8rem;
	--border-radius-3: 1.2rem;

	--card-padding: 1.8rem;
	--padding-1: 1.2rem;

	--box-shadow: 0 2rem 3rem var(--color-light);
	--option-toggle-box-shadow: 0 0 6px -3px var(--color-light);

	--color-btn-get-started: #00c1fd;
	--color-btn-accept-terms: #15fc02;
	--color-btn-decline-terms: #ff0042;
	--color-btn-modal-quit: #7d8da1;
	--color-btn-modal-access: #00c1fd;
	--color-btn-disabled: #d4d4d4;
	--color-btn-text-disabled: #0000003d;

	/* main */
	--color-option-toggle-background: #0778a9;
	--color-option-toggle-label: #424242;
	--color-option-toggle-switch-background: #e0e0e0;
	--color-option-toggle-switch-on: #012a69;

	--color-message-receiver-bubble: #e6eae5;
	--color-reaction-button-border: #edaa00;

	/* modals */
	--default-system-blue-light: #007aff;
	--modal-help-header-background-color: #1c5687;
	
	/* image load */
	--textSectionAlign: end;
	--splashCopyTopMargin: 20vh;

	/* thread */
	--header-background-color: #1c5687;
	--thread-message-background-color: #e5e5ea;
	--reaction-button-selected-color: #007aff;
	--new-message-notification-color: #007aff;
	--reation-button-border-color: #adadad;
	--thread-max-width: 720px;

	/* snackbar */
	--color-error-snackbar: #c33a32;

	/* survey */
	--survey-background-color: #e5e5ea;
	--survey-divider-color: #c7c7cc;
	--survey-radio-border-color: #3c3c435c;
	--survey-textarea-label-color: #707070;
	--survey-rating-key-text-color: #0000008f;
	--survey-button-color: #34c759;
}

.mat-mdc-snackbar-surface {
	padding: 0 !important;
	margin-inline: 1rem !important;
	margin-bottom: 2rem !important;
	--mdc-snackbar-supporting-text-font: "Noto Sans", sans-serif;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
	border-radius: 0.5rem !important;
}

.mdc-snackbar__label {
	padding: 1rem 1rem 1.5rem 1rem !important;
	border-radius: 0.5rem;
}

.cookies-snackbar {
	--mdc-snackbar-container-color: var(--color-primary);
}

.user-notification-info-snackbar {
	--mdc-snackbar-container-color: var(--color-success);
}

.user-notification-warning-snackbar {
	--mdc-snackbar-container-color: var(--color-warning);
}

.user-notification-error-snackbar {
	--mdc-snackbar-container-color: white;
}

.container {
	max-width: 600px;
	min-height: 640px;
	overflow-y: auto;
}

.row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.column {
	display: flex;
	flex-direction: column;
}

.full-center {
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
}

.hidden {
	display: none;
}

.disabled {
	pointer-events: none;
	/* opacity: 0.3; */
}

app-footer {
	width: 100%;
}

button.btn-continue {
	background-color: var(--default-system-blue-light);
	background-repeat: no-repeat;
	color: var(--color-white);
	border-width: 0;
	margin: 1rem 0 0 0;
	border-radius: 5rem;
	width: 100%;
	max-width: 350px;
	min-width: 175px;
	height: 2.5rem;
}

button.disabled {
	background-color: var(--color-btn-disabled);
	color: var(--color-btn-text-disabled);
}

.button-center-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
}

